.login-right-section {
  .image-container {
    position: relative;
    /* Set your desired width and height for the image */
    width: 100%; /* or specify fixed width */
    height: 100vh; /* or specify fixed height */
    background-image: url("../../../images/loginImage.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  } 
  
  @media screen and (max-width: 768px) {
    display: none !important;
  }
  // img {
  //   width: 100%;
  //   height: 265px;
  //   object-fit: contain;
  //   opacity: 1;
  //   margin-bottom: 55px;
  // }
  .app-title-and-tagline {
    font-size: 70px;
    font-weight: 700;
    color: #fff;    
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  }
  .main-tagline {
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    margin-top: 90px;
  }

  .sub-tagline {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
}

.login-left-section {
  display: flex !important;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    margin-top: 50% !important;
    // background: #0e136d !important;
  }

  .login-card {
    width: 380px;
    border-radius: 14px;
    -webkit-box-shadow: 1px 1px 19px -2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 1px 1px 19px -2px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 19px -2px rgba(0, 0, 0, 0.25);
    position: relative;

    .login-title {
      font-weight: bolder;
      font-size: 24px;
      margin-bottom: 30px;
      text-align: center;
    }

    .logo-container {
      width: 100%;
      display: flex;
      justify-content: center;
      // margin-bottom: 30px;
      // margin-top: 20px;

      .logo {
        width: 150px;
      }
    }

    .password-field {
      margin-bottom: 5px;
    }

    .forgot-password-btn-container {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0px;

      .ant-btn-link {
        color: #2e404f;
        font-weight: 500;
      }
    }

    .ant-btn-lg {
      padding: 4px 30px !important;
    }
  }
}
